<template>
  <div class="funds-info">
    <div v-if="companyInfo" class="funds-info__body">
      <div class="funds-info__account-name">{{ companyInfo.accountName }}</div>
      <div class="funds-info__user-name">{{ activeAccount.legal_name }}</div>
      <div class="funds-info__user-email">{{ activeAccount.email }}</div>
      <div class="funds-info__live-funds">{{ $t('funds.actual_money') }}: {{ liveFunds ? companyInfo.platform  === 'google' ? numberWithSpacesFixed(liveFunds.total, 2) : numberWithSpacesCeil(liveFunds.total) : 0 }} {{$t('currency.currency_symbol')}}</div>
      <div class="funds-info__cashback">{{ $t('funds.cashback') }}: {{ cashBack ? companyInfo.platform  === 'google' ? numberWithSpacesFixed(cashBack.total, 2) : numberWithSpacesCeil(cashBack.total) : 0 }} {{$t('currency.currency_symbol')}}</div>
    </div>
  </div>
</template>

<script>
import numberWithSpacesCeil from '@/mixin/numberWithSpaces'
import numberWithSpacesFixed from '@/mixin/numberWithSpaces'

export default {
  name: 'FundsInfo',
  mixins: [numberWithSpacesCeil, numberWithSpacesFixed],
  data() {
    return {
      liveFunds: null,
      cashBack: null,
    }
  },
  computed: {
    companyInfo () {
      return this.$store.state.Company.companyData
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
  },
  created() {
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    const accounts = {
      account_ids: [this.companyInfo.accountId]
    }
    this.$store.dispatch('Company/getFunds', { data: accounts, token })
      .then((data) => {
        const fundsFata = data.data.data
        this.liveFunds = fundsFata.find(el => el.type_id === 2)
        this.cashBack = fundsFata.find(el => el.type_id === 7)
      })
      .catch((err) => {
        console.log(err.data.errors)
      })
  },
  methods: {
    cancel() {
      this.$store.dispatch('Dialog/closeDialog')
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";
  .funds-info {
    padding-top: 3rem;

    &__account-name {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: .8rem;
      color: $basicBlack;
    }

    &__user-name {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-bottom: .6rem;
      color: $gray2;
    }

    &__user-email {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $gray2;
      padding-bottom: 1.4rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $gray3;
    }

    &__live-funds, &__cashback {
      font-weight: 700;
      font-size: 1.6rem;
      color: $basicBlack;
      margin-bottom: 1.2rem;
    }
  }
</style>
